import { useEffect, useState } from "react";

import axios from "axios";
import { token } from "localStorage";
const baseUrl = process.env.REACT_APP_BASE_URL;

function useCustomerSearch(search, page, pageSize, sort, validflag = true) {
    console.log(page)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setCustomers([]);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    let cancel;

    axios({
      headers: { Authorization: `Bearer ${token()}` },
      method: "GET",
      url: baseUrl + "/customer/list",
      params: { search, page, pageSize, sort, validflag },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setCustomers((prevCustomers) => {
          console.log("prevCustomers:", prevCustomers);
          console.log("res.data.customers:", res.data.customers);
          // let arr = [prevCustomers, res.data.customers];
          let arr = prevCustomers.concat(res.data.customers);

          console.log("arr: ", arr);
          setHasMore(arr.length < res.data.total);
          return arr;
        });
        setTotal(res.data.total);
        setLoading(false);
        console.log("Total: ", res.data.total);
        console.log(page);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [search, page, pageSize, sort, validflag]);
  return { customers, total, loading, error, hasMore };
}

export default useCustomerSearch;
