import React, { useState, useEffect, useRef } from "react";
import { QueryProvinces, QueryDistrists, QueryVillages } from "api/general";
import {
  GetAllCustomers,
  GetCustomersList,
  PatchCustomer,
} from "api/customers";
import {
  Box,
  useTheme,
  FormControl,
  IconButton,
  Grid,
  InputBase,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Input,
  DialogContent,
  Dialog,
  DialogTitle,
  Slide,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CancelIcon from "@mui/icons-material/Cancel";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import { capitalizeFirstLetter } from "utils";
import useCustomerSearch from "customHooks/useCustomerSearch";
import { useCallback } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  zIndex: 1,
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CustomerListCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [customerId, setCustomerId] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [villages, setVillages] = useState([]);
  const [villageId, setVillageId] = useState("");
  const [remark, setRemark] = useState("");
  const [validflag, setValidflag] = useState(true);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  // values to set to cards
  const [rows, setRows] = useState([]);

  const { customers, total, hasMore, loading, error } = useCustomerSearch(
    search,
    page,
    pageSize,
    sort
  );

  const observer = useRef();
  const lastCustomerElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Visible");
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    QueryProvinces().then((res) => setProvinces(res.data));
  }, []);

  useEffect(() => {
    if (provinceId) {
      setVillageId("");
      setVillages([]);
      setDistrictId("");
      setDistricts([]);
      QueryDistrists(provinceId).then((res) => setDistricts(res.data));
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId) {
      setVillageId("");
      QueryVillages(districtId).then((res) => setVillages(res.data));
    }
  }, [districtId]);

  const formSubmittedHandler = (e) => {
    e.preventDefault();
    const provinceObj = provinces.find(
      (item) => item.pr_id === provinceId.toString()
    );
    const districtObj = districts.find(
      (item) => item.dt_id === districtId.toString()
    );
    const villageObj = villages.find(
      (item) => item.vill_id === villageId.toString()
    );

    PatchCustomer(
      customerId,
      name,
      whatsapp,
      provinceObj,
      districtObj,
      villageObj,
      validflag,
      remark
    ).then((res) => {
      if (res.status === 200) {
        // update object data in rows
        let objIndex = rows.findIndex((obj) => obj._id === customerId);
        rows[objIndex].name = name;
        rows[objIndex].whatsapp = whatsapp;
        rows[objIndex].province = provinceObj;
        rows[objIndex].district = districtObj;
        rows[objIndex].village = villageObj;
        rows[objIndex].validflag = validflag;
        rows[objIndex].remark = remark;

        setOpenEditDialog(false);
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1000,
        });
      } else if (res.status === 400) {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  const buttonEditHandler = (event, customer) => {
    const {
      _id,
      name: customerName,
      whatsapp,
      province,
      district,
      village,
      remark,
      validflag,
    } = customer;

    setProvinceId(province.pr_id);
    QueryDistrists(province.pr_id).then((res) => {
      setDistricts(res.data);
      setDistrictId(district.dt_id);
    });
    QueryVillages(district.dt_id).then((res) => {
      setVillages(res.data);
      setVillageId(village.vill_id);
    });

    setName(customerName);
    setWhatsapp(whatsapp);
    setCustomerId(_id);
    setRemark(remark);
    setValidflag(validflag);
    setOpenEditDialog(true);
  };

  const searchtHandler = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setPage(0);
  }, [search]);
  var randomColor;

  useEffect(() => {
    if (!loading) {
      setRows(customers);
    }
  }, [customers, loading]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <FormControl fullWidth>
            <FlexBetween
              backgroundColor={theme.palette.background.alt}
              borderRadius="9px"
              gap="3rem"
              p="0.5rem 1rem"
            >
              <InputBase
                placeholder="Name, WhatsApp number ..."
                id="search"
                autoFocus
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchtHandler(e);
                  }
                }}
              />
              <IconButton onClick={searchtHandler}>
                <SearchIcon />
              </IconButton>
            </FlexBetween>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        {!loading &&
          rows &&
          rows.map((customer, index) => {
            randomColor = Math.floor(Math.random() * 16777215).toString(16);
            if (rows.length === index + 1) {
              return (
                <Grid
                  item
                  ref={lastCustomerElementRef}
                  key={customer._id}
                  xs={12}
                  md={6}
                  lg={4}
                >
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ bgcolor: "#" + randomColor, color: "white" }}
                          aria-label="recipe"
                        >
                          {customer.name.toUpperCase().slice(0, 2)}
                        </Avatar>
                      }
                      action={
                        <IconButton
                          aria-label="Edit"
                          onClick={(event) => {
                            buttonEditHandler(event, customer);
                          }}
                        >
                          <ModeEditOutlineIcon />
                        </IconButton>
                      }
                      title={
                        capitalizeFirstLetter(customer.name) +
                        " - " +
                        customer.whatsapp
                      }
                      subheader={customer.province.pr_name}
                    />
                    <CardContent>
                      <IconButton aria-label="Arrived">
                        <BeenhereIcon /> Arrived
                      </IconButton>
                      <IconButton>
                        <LocalShippingIcon aria-label="In-progress" />{" "}
                        In-progress
                      </IconButton>
                      <IconButton>
                        <CancelIcon aria-label="Cancel" /> Cancel
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              );
            } else {
              return (
                <Grid item key={customer._id} xs={12} md={6} lg={4}>
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ bgcolor: "#" + randomColor, color: "white" }}
                          aria-label="recipe"
                        >
                          {customer.name.toUpperCase().slice(0, 1)}
                        </Avatar>
                      }
                      action={
                        <IconButton
                          aria-label="Edit"
                          onClick={(event) => {
                            buttonEditHandler(event, customer);
                          }}
                        >
                          <ModeEditOutlineIcon />
                        </IconButton>
                      }
                      title={
                        capitalizeFirstLetter(customer.name) +
                        " - " +
                        customer.whatsapp
                      }
                      subheader={customer.province.pr_name}
                    />
                    <CardContent>
                      <IconButton aria-label="Arrived">
                        <BeenhereIcon /> Arrived
                      </IconButton>
                      <IconButton>
                        <LocalShippingIcon aria-label="In-progress" />{" "}
                        In-progress
                      </IconButton>
                      <IconButton>
                        <CancelIcon aria-label="Cancel" /> Cancel
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
          })}
      </Grid>
      <Grid container mt={2}>
        <Grid item md={12}>
          {loading && <LinearProgress />}
        </Grid>
      </Grid>
      <Box maxWidth={600}>
        <form onSubmit={formSubmittedHandler}>
          <BootstrapDialog
            fullWidth
            onClose={() => setOpenEditDialog(false)}
            aria-labelledby="customized-dialog-title"
            open={openEditDialog}
            TransitionComponent={Transition}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenEditDialog(false)}
            >
              Edit Customer
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <Input
                    id="name"
                    aria-describedby="name-helper-text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                    value={name}
                  />
                  <FormHelperText id="name-helper-text">
                    Customer name.
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel htmlFor="whatsapp">Whatsapp</InputLabel>
                  <Input
                    type="number"
                    id="whatsapp"
                    aria-describedby="whatsapp-helper-text"
                    onChange={(e) => {
                      setWhatsapp(e.target.value);
                    }}
                    required={true}
                    value={whatsapp}
                  />
                  <FormHelperText id="whatsapp-helper-text">
                    Whatsapp number example: 8562077790708.
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel>Province / ແຂວງ</InputLabel>
                  <Select
                    id="province"
                    defaultValue=""
                    value={!provinceId ? "" : provinceId}
                    label="Province / ແຂວງ"
                    onChange={(event) => setProvinceId(event.target.value)}
                    required={true}
                  >
                    {provinces &&
                      provinces.map(({ pr_id, pr_name, pr_name_en }) => {
                        return (
                          <MenuItem key={pr_id} value={pr_id}>
                            {pr_name} / {pr_name_en}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel>District / ເມືອງ</InputLabel>
                  {districts && (
                    <Select
                      id="districts"
                      // defaultValue=""
                      defaultValue=""
                      value={districtId === "" ? "" : districtId}
                      label="District / ເມືອງ"
                      onChange={(event) => setDistrictId(event.target.value)}
                      disabled={!Boolean(provinceId)}
                      required={true}
                    >
                      {districts &&
                        districts.map(({ dt_id, dt_name, dt_name_en }) => {
                          return (
                            <MenuItem key={dt_id} value={dt_id}>
                              {dt_name} / {dt_name_en}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                </FormControl>
              </Box>

              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel> Village / ບ້ານ </InputLabel>
                  {villages && (
                    <Select
                      id="villages"
                      value={!villageId ? "" : villageId}
                      label="Village / ບ້ານ"
                      onChange={(event) => setVillageId(event.target.value)}
                      disabled={!districtId}
                      required={true}
                    >
                      {villages &&
                        villages.map(({ vill_id, vill_name, vill_name_en }) => {
                          return (
                            <MenuItem key={vill_id} value={vill_id}>
                              {vill_name}{" "}
                              {!!vill_name_en ? ` / ${vill_name_en}` : ""}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    value={remark}
                    multiline
                    maxRows={5}
                    label="Remark / ໝາຍເຫດ"
                    // placeholder="Description"
                    id="remark"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={validflag}
                      onChange={() => setValidflag(!validflag)}
                    />
                  }
                  label="Validflag"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <FormControl>
                <Button
                  disabled={!villageId || !districtId || !provinceId}
                  variant="contained"
                  color="secondary"
                  autoFocus
                  // type="submit"
                  size="large"
                  onClick={formSubmittedHandler}
                >
                  Save changes
                </Button>
              </FormControl>
            </DialogActions>
          </BootstrapDialog>
        </form>
      </Box>
    </Box>
  );
};

export default CustomerListCard;
