import React, { useEffect, useState } from "react";
import Header from "components/Header";
import {
  InputBase,
  Box,
  IconButton,
  useTheme,
  Grid,
  FormControl,
  Divider,
  Typography,
  TableContainer,
  TableHead,
  TableBody,
  Table,
  TableRow,
  useMediaQuery,
  Paper,
  Chip,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import SearchIcon from "@mui/icons-material/Search";
import FlexBetween from "components/FlexBetween";
import { GetRouteDetail } from "api/routes";
import { GetPackageDetailByTracking } from "api/packages";
import { GetTrackingLog } from "api/trackingLogs";
import Swal from "sweetalert2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

// Steper Component
import Stack from "@mui/material/Stack";
import moment from "moment";
import { getScanText, selectAndFocus } from "utils";
import Scanner from "components/Scanner";

const Tracking = () => {
  const theme = useTheme();
  const [searchTracking, setSearchTracking] = useState("");
  const [currentStation, setCurrentStation] = useState("");
  const [open, setOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const [currentStep, setCurrentStep] = useState(0);
  const [navigator, setNavigator] = useState([]);
  const [trackingLog, setTrackingLog] = useState(null);
  const [packageDetail, setPackageDetail] = useState(null);

  useEffect(() => {
    if (navigator) {
      navigator.forEach((item, index) => {
        if (item.station === currentStation) {
          console.log(item.station, index);
          setCurrentStep(index + 1);
        }
      });

      GetTrackingLog(getScanText(searchTracking)).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setTrackingLog(res.data);
        } else {
          Swal.fire({
            title: "Tracking log not found",
            icon: "error",
            timer: 1000,
          });
        }
      });
    }
  }, [navigator]);

  const formSearchTrackingHandler = (tracking) => {
    let newTracking = getScanText(tracking);
    setSearchTracking(newTracking);

    GetPackageDetailByTracking(newTracking).then(async (res) => {
      if (res.status === 200) {
        //Get currentSation and routeId from package detail
        setPackageDetail(res.data);
        setCurrentStation(res.data.station);
        GetRouteDetail(res.data.routeId).then((res) => {
          if (res.status === 200) {
            // set Steps from route.navogator
            console.log(res.data.navigator);
            setNavigator(res.data.navigator);
          } else {
            console.log(res.message);
          }
        });
      } else if (res.status === 204) {
        setTrackingLog(null);
        setPackageDetail(null);
        Swal.fire({
          title: "Data not found",
          icon: "warning",
          text: `Tacking number [${newTracking}] doesn't exist`,
          timer: 1500,
        });
        selectAndFocus("searchTracking");
        setCurrentStep(0);
        setNavigator([]);
      } else {
        setTrackingLog(null);
        setPackageDetail(null);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res.message,
          timer: 1500,
        });
        selectAndFocus("searchTracking");
      }
    });
  };

  return (
    <Box mx={1}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Header
            title="Tracking"
            subtitle="Enter tracking number to see the transactions"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xl={6} md={6} xs={12}>
          {/* Scan package section */}
          <FormControl fullWidth={!isNonMobile}>
            <FlexBetween
              backgroundColor={theme.palette.background.alt}
              borderRadius="9px"
              gap="3rem"
              p="0.5rem 1rem"
            >
              <InputBase
                placeholder="Tracking number"
                id="searchTracking"
                autoFocus
                value={searchTracking}
                onChange={(e) => setSearchTracking(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    formSearchTrackingHandler(e.target.value);
                  }
                }}
              />
              {isNonMobile ? (
                <IconButton
                  onClick={() => formSearchTrackingHandler(searchTracking)}
                >
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setOpen(true)}>
                  <CameraAltIcon />
                </IconButton>
              )}
            </FlexBetween>
          </FormControl>
        </Grid>
      </Grid>
      {packageDetail && (
        <Paper
          sx={{
            maxWidth: 600,
            borderRadius: 2,
            px: {xl: 4, md: 4, xs: 2 },
            pt: 2,
            pb: 5,
            mt: 1,
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Grid item xl={12} md={12} xs={12} my={3}>
            {/*  Tracking details section  */}
            <Stack spacing={2}>
              <Stepper alternativeLabel activeStep={currentStep}>
                {navigator.map(({ station, stationName }, index) => (
                  <Step
                    key={station}
                    sx={[
                      {
                        "& .MuiStepLabel-root .Mui-active": {
                          color: theme.palette.grey[400], // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: theme.palette.secondary[600], // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "grey.500", // Just text label (COMPLETED)
                          },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                          {
                            color: theme.palette.grey[500], // Just text label (ACTIVE)
                          },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "white", // circle's number (ACTIVE)
                        },
                      },
                      station === currentStation && {
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: theme.palette.secondary[300], // circle color (COMPLETED)
                        },
                      },
                    ]}
                  >
                    <StepLabel>{stationName}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </Grid>

          <Grid item xl={12} md={12} xs={12} my={1}>
            {packageDetail && (
              <>
                <FlexBetween>
                  <Box>
                    <Typography color="secondary">
                      {packageDetail.tracking}
                    </Typography>
                    <Typography>
                      {packageDetail.customer.name}
                      {" - "}
                      {packageDetail.customer.whatsapp}
                    </Typography>
                  </Box>
                  {packageDetail.shippingFee && (
                    <Box>
                      <Chip
                        label={"Fee: ฿" + packageDetail.shippingFee}
                        variant="outlined"
                        color="secondary"
                        sx={{ fontSize: 14, px: 2, height: 44 }}
                      />
                    </Box>
                  )}
                </FlexBetween>
                <Typography marginY={1}>{packageDetail.description}</Typography>
                {packageDetail.remark && (
                  <Typography color={theme.palette.secondary[300]}>
                    Remark: {packageDetail.remark}
                  </Typography>
                )}

                <Typography
                  color={theme.palette.grey[500]}
                  sx={{ fontStyle: "italic" }}
                >
                  Order#{packageDetail.orderId}
                </Typography>
              </>
            )}
          </Grid>

          <Stack
            direction={"row"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <Stepper activeStep={currentStep + 1} orientation="vertical">
              {trackingLog &&
                trackingLog.map((item, index) => (
                  <Step
                    key={index}
                    active={true}
                    sx={[
                      {
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "secondary.dark", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "grey.500", // Just text label (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "secondary.main", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                          {
                            color: "common.white", // Just text label (ACTIVE)
                          },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "black", // circle's number (ACTIVE)
                        },
                      },
                      index !== 0 && {
                        "& .MuiStepLabel-root .Mui-active": {
                          color: theme.palette.secondary[600], // circle color (ACTIVE)
                        },
                      },
                    ]}
                  >
                    <StepLabel
                    // optional={
                    //   index === 2 ? (
                    //     <Typography variant="caption">Last step</Typography>
                    //   ) : null
                    // }
                    >
                      <Typography
                        color={theme.palette.secondary[200]}
                        sx={{
                          display: "flex",
                          justifyItems: "center",
                        }}
                        variant="h5"
                      >
                        {item.station}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Box>
                        <Typography>{item.description}</Typography>
                        <FlexBetween mt={1}>
                          <Typography
                            color={theme.palette.grey[500]}
                            sx={{ fontStyle: "italic" }}
                          >
                            {moment(item.createdAt)
                              .tz("Asia/Vientiane")
                              .format("DD/MM/YYYY h:mm:ss A")}
                          </Typography>
                          <Typography
                            sx={{ fontStyle: "italic" }}
                            color={theme.palette.grey[500]}
                          >
                            Action by {item.actionByUser}.
                          </Typography>
                        </FlexBetween>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
            </Stepper>
          </Stack>
        </Paper>
      )}
      <Box my={1} height={2}></Box>

      <Scanner
        open={open}
        setOpen={setOpen}
        closeAfterResult={true}
        onDecode={(result) => {
          if (result) {
            formSearchTrackingHandler(result);            
          }
        }}
      />
    </Box>
  );
};

export default Tracking;
