import React, { useEffect, useState } from "react";
import Header from "components/Header";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import CameraAltIcon from "@mui/icons-material/CameraAlt";

import moment from "moment-timezone";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FlexBetween from "components/FlexBetween";
import { useTheme } from "@emotion/react";
import { GetPackagesByStationAndDate, scanPackage } from "api/packages";

import { GetStationDetailById } from "api/stations";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import {
  getScanText,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
  selectAndFocus,
} from "utils";
import { user, defaultStation } from "localStorage";
import Scanner from "components/Scanner";

const Scan = () => {
  const stationList = user().stations;
  const _defaultStation = defaultStation();
  const theme = useTheme();
  const [tracking, setTracking] = useState("");
  const [selectedStation, setSelectedStation] = useState(_defaultStation);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [rows, setRows] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [succesCount, setSuccessCount] = useState(0);
  const [open, setOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");

  function RefreshPackagesList(
    from,
    to,
    station,
    page,
    pageSize,
    sort,
    search
  ) {
    GetPackagesByStationAndDate(
      from,
      to,
      station,
      page,
      pageSize,
      sort,
      search
    ).then((res) => {
      // console.log(res);

      if (res.status === 200) {
        setRows(res.data.packages);
        setTotalCount(res.data.totalCount);
      } else {
        console.log(res.message);
      }
    });
  }

  useEffect(() => {
    let newFromDate = moment(fromDate).format("YYYY-MM-DD");
    let newToDate = moment(toDate).format("YYYY-MM-DD");
    // console.log(`From: ${newFromDate} - To: ${newToDate}`);
    if (selectedStation) {
      RefreshPackagesList(
        newFromDate,
        newToDate,
        selectedStation.id,
        page,
        pageSize,
        sort,
        search
      );
    }
  }, [
    fromDate,
    toDate,
    selectedStation,
    page,
    pageSize,
    sort,
    search,
    succesCount,
  ]);

  // useEffect(() => {
  //   setStationList(_user.stations);
  //   setSelectedStation(_defaultStation);
  // }, []);

  const handleFormSubmit = () => {
    scanPackage(getScanText(tracking), selectedStation.id).then((res) => {
      console.log(res);
      if (res && res.status === 200) {
        playCorrectSound();
        setTracking("");
        setSuccessCount(succesCount + 1);
        Swal.fire({
          icon: "success",
          title: `Success!`,
          text: `This package has been updated`,
          timer: 500,
        });
      } else if (res.status === 202) {
        // In case data not found
        playAlertSound();
        Swal.fire({
          title: res.data.status,
          icon: "warning",
          text: res.data.message,
          timer: 1200,
        });
        selectAndFocus("tracking");
      } else {
        playIncorrectSound();
        Swal.fire({
          title: res.data.status,
          icon: "error",
          text: res.data.message,
          timer: 1500,
        });
        selectAndFocus("tracking");
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.5,
      valueGetter: (params) => params.row.customer.name,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 0.7,
    },
    {
      field: "tracking",
      headerName: "Tracking",
      flex: 0.7,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    // {
    //   field: "routeId",
    //   headerName: "Route ID",
    //   flex: 0.3,
    // },
    // {
    //   field: "amount",
    //   headerName: "Amount",
    //   flex: 0.5,
    // },
    // {
    //   field: "quantity",
    //   headerName: "QTY",
    //   flex: 0.5,
    // },
    // {
    //   field: "station",
    //   headerName: "Station",
    //   flex: 0.3,
    //   h
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 0.4,
    // },
    {
      field: "shippingFee",
      headerName: "Shipping",
      flex: 0.3,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.7,
      valueGetter: (params) =>
        moment(params.row.updatedAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.7,
      valueGetter: (params) =>
        moment(params.row.createdAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
  ];

  return (
    <Box m="0 1rem">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Header title="Scan package" />
        </Grid>
        <Grid item xl={8} md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select station</InputLabel>
                <Select
                  label="Select station"
                  value={selectedStation ? selectedStation.id : ""}
                  onChange={(e) => {
                    GetStationDetailById(e.target.value).then((res) => {
                      if (res.status === 200) {
                        setSelectedStation(res.data);
                        selectAndFocus("tracking");
                        // console.log("Select station", res.data);
                      }
                    });
                  }}
                  required
                >
                  {stationList &&
                    stationList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="9px"
                    gap="3rem"
                    p="0.5rem 1rem"
                  >
                    <InputBase
                      placeholder="Tracking number"
                      id="tracking"
                      autoFocus
                      value={tracking}
                      onChange={(e) => setTracking(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setTracking(getScanText(tracking));
                          // formSubmitHandler();
                          handleFormSubmit();
                        }
                      }}
                    />
                    {isNonMobile ? (
                      <IconButton onClick={handleFormSubmit}>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setOpen(true)}>
                        <CameraAltIcon />
                      </IconButton>
                    )}
                  </FlexBetween>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Filter From"
                    inputFormat="DD/MM/yyyy"
                    value={fromDate}
                    maxDate={toDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Filter To"
                    inputFormat="DD/MM/yyyy"
                    minDate={fromDate}
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12}>
          {/*  Data Grid  */}
          <Box
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={rows || []}
              columns={columns}
              rowCount={totalCount || 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              sortingMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbar }}
              componentsProps={{
                toolbar: { searchInput, setSearchInput, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Scanner
        open={open}
        setOpen={setOpen}
        onDecode={(result) => {
          if (result) {
            console.log(result);
            setTracking(() => getScanText(result));
            handleFormSubmit();
          }
        }}
      />
    </Box>
  );
};

export default Scan;
